import * as tf from './node_modules/@tensorflow/tfjs';
import './node_modules/@tensorflow/tfjs-backend-wasm';
import * as tflite from './node_modules/@tensorflow/tfjs-tflite';
import * as tfd from './node_modules/@tensorflow/tfjs-data';
import Compressor from 'compressorjs';
import * as cv from './js/opencv.js'; 

let webcam;
let segmentationModel;
let model;
let width, height;
let img_height = 256;
let img_width = 256;
let name = 'tfjs_v6_100_256';
let model_path;
let SCALE_MULTIPLIER = 0.8;
let SCALE_ZOOM = 1;
let MAX_SCALE_ZOOM = 2;
let MIN_SCALE_ZOOM = 1;
const DEFAULT_SCALE_ZOOM = 1;
const DEFAULT_SCALE_MULTIPLIER = 0.8;
let LiveTryOn = false;
model_path = 'tfjs_v6_100_256/hair_segmentation_rework_tf_mobilenetv3_large_minimal_100_imagenet_v3_focal_cont_gtmatt_3losses_index_epoch_59_float16.tflite';
// Try-on-Variables
let FPS_Section = document.getElementById('fps-section');
let Controls_Section = document.getElementById('controlsSection');
let Try_On_Area = document.getElementById('try-on-area');
let Scroll_Down_Target =  document.getElementById("scrollto");
let Output_Canvas = document.getElementById('outputCanvas');
let Back_Button = document.getElementById('backButton');
let Input_Canvas = document.getElementById('tryon-live-canvas-0');
let Input_Video = document.getElementById('webcam');
let Input_Image = document.getElementById('img2');
let Output_Video = document.getElementById('mask');
let Try_On_Controls_Section = document.getElementById('try-on-section');
let tabsContainer = document.getElementById('tabsContainer');
let colorPaletteContainer = document.getElementById('colorPaletteContainer');
let Flipper_Icon = document.getElementById('flipper'); 
let TopContentSection = document.getElementById('margin-class');
let currentCamera = 'user';
let continuePredicting = true;
let TryOnBtn = document.getElementById('showTryonbtn');
var CanvasesContainer = document.getElementById('canvasesContainer');
const selectedShadeNameDiv = document.getElementById('selectedShadeName');
let Tryon_Media_Container = document.getElementById('container-tryon-media');
let Tryon_Container = document.querySelector('.container-tryon-media'); 

// console.log(model_path,img_size)

// Loads mobilenet and returns a model that returns the internal activation
// we'll use as input to our classifier model.
// async function loadTruncatedMobileNet() {
//   const segmentationModel = await tf.loadGraphModel(model_path);
//   return segmentationModel;
// }

async function loadModel() {
  await tflite.setWasmPath(
    'https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-tflite@0.0.1-alpha.8/dist/'
  );
  // console.log(model_path);
  const model = await tflite.loadTFLiteModel(model_path);
  return model;
}

let hairColor = [null,null,null];

let adjustWithLuminance = .25;
/////////////////////////////////////////////////////////////////////////*****************************************88



/////////////////////////////////////////////////////////////////////////////////////////////////////////**************************************
function averageLuminanceFiltered(imageData, maskData) {
  
  var luminanceSum = 0;
  var pixelCount = 0;

  for (var i = 0; i < imageData.length; i += 3) {
      // Apply mask: Only consider pixels where mask value is 1
      if (maskData[i / 3] > 0.5) {
          // Get grayscale value from RGB (assuming image is in RGBA format)
          var grayValue = 0.2126 * imageData[i] + 0.7152 * imageData[i + 1] + 0.0722 * imageData[i + 2];
          luminanceSum += grayValue;
          pixelCount++;
      }
  }

  return luminanceSum / pixelCount;
}
function mapValue(value, fromLow, fromHigh, toLow, toHigh) {
  // Map value from the input range to the output range
  return toLow + (toHigh - toLow) * ((value - fromLow) / (fromHigh - fromLow));
}
// Map luminance based on average intensity and range
function mapLuminance(averageIntensity, lowerLuminance, upperLuminance) {
  var upperIntensity = 1;
  var lowerIntensity = 0;

  // Calculate the total intensity range
  var intensityRange = upperIntensity - lowerIntensity;

  // Calculate the luminance range
  var luminanceRange = upperLuminance - lowerLuminance;

  // Calculate the scaled intensity within the range [0, 1]
  var scaledIntensity = (averageIntensity - lowerIntensity) / intensityRange;

  // Map the scaled intensity to the luminance range
  var mappedLuminance = lowerLuminance + (scaledIntensity * luminanceRange);

  return mappedLuminance;
}
/////////////////////////////****************************


const sobelX = tf.tensor2d([
  [-1, 0, 1],
  [-2, 0, 2],
  [-1, 0, 1]
], [3, 3]);

const sobelY = tf.tensor2d([
  [-1, -2, -1],
  [0, 0, 0],
  [1, 2, 1]
], [3, 3]);

// Function to apply Sobel filters
// Function to apply Sobel filters to a single-channel image
function applySobelFilters(grayscaleImage) {
  // Expand dimensions to create a 4D tensor [1, height, width, 1]
  const expandedImage = grayscaleImage.expandDims(0).expandDims(-1);

  // Create 4D Sobel filters [3, 3, 1, 1]
  const sobelXFilter = sobelX.expandDims(2).expandDims(3);
  const sobelYFilter = sobelY.expandDims(2).expandDims(3);

  const sobelXImage = tf.conv2d(expandedImage, sobelXFilter, [1, 1], 'same');
  const sobelYImage = tf.conv2d(expandedImage, sobelYFilter, [1, 1], 'same');

  const gradientMagnitude = tf.sqrt(sobelXImage.square().add(sobelYImage.square()));

  // Remove the added dimensions to return to shape [height, width, 1]
  return gradientMagnitude.squeeze();
}
////////////////////********************************
function calculatePercentile(array, percentile) {
    const sortedArray = array.slice().sort((a, b) => a - b);
    const index = Math.ceil((percentile / 100) * sortedArray.length);
    return sortedArray[index - 1];
}
function blendMediapipe(image, weight,adjustWithLuminance,additionLuminance,avg) {
//console.log("Blending code1")
  let mean = tf.tensor1d([0.299, 0.587, 0.114]);
  let inputStrength;
  if (window.location.pathname.includes('/builder.html')) { 
  inputStrength = parseFloat(document.getElementById("strength").value);
  adjustWithLuminance = (1 - inputStrength / 100.0);
  }
  else{
  adjustWithLuminance = (1 - 0 / 100.0);
  }
 let luminance = tf.tidy(() => image.mul(mean).sum(2).mul(adjustWithLuminance).add(additionLuminance).sub(adjustWithLuminance));

  // weight = tf.where(mask2, weight.add(0.4), weight);
   //weight = tf.where(mask3, weight.add(0.6), weight);
  //console.log("Blending code2")

   
  
  const color = tf.tensor1d(hairColor);
  //color=color.sub(10.9)
  const mixValue = tf.tidy(() => weight.mul(luminance).expandDims(2));
  const ones = tf.onesLike(image);
  
  ///////////////////////////*****************************************


// Find edges using a Canny edge detection algorithm
// Define Sobel filters for edge detection
// Define Sobel filters for edge detection




//console.log("Blending code3")

const blended = tf.tidy(() => {

    const blendedWithWhiteReflections = image.mul(ones.sub(mixValue)).add(mixValue.mul(color));

    // Combine the blended areas with the original image
    //if (hairColor[0] <0 && hairColor[1] <0)
    //{blendedWithWhiteReflections = blendedWithWhiteReflections.clipByValue(0, 1).add(resultTensor.expandDims(2));}

    return blendedWithWhiteReflections.clipByValue(0, 1);
});
//console.log("Blending code4")
////////////////////////////////////***********************************

//const blended = tf.tidy(() => image.mul(ones.sub(mixValue)).add(mixValue.mul(color)).clipByValue(0, 1));
  color.dispose();
  luminance.dispose();
  mixValue.dispose();
  ones.dispose();
  //weight.dispose();
  image.dispose();
  mean.dispose();
  return blended;
}


let outputWidth;
let outputHeight;
if (window.location.pathname.includes('/builder.html')) { 
document.addEventListener("DOMContentLoaded", () => {
  Coloris({
    alpha: false,
    format: 'rgb',
    theme: 'polaroid'
  });
});
}

// document.addEventListener('coloris:pick', event => {

//   var rgb = event.detail.color;
//   rgb = rgb.substring(4, rgb.length-1).replace(/ /g, '').split(',');

//   // console.log('New color', event.detail.color);
//   hairColor = [parseFloat(rgb[0]/255.0), parseFloat(rgb[1]/255.0), parseFloat(rgb[2]/255.0)]

// });
function handleColorSelection(rgbValues) {
  const normalizedRGB = rgbValues.map(value => value / 255.0);
  hairColor = normalizedRGB;
}
document.addEventListener('coloris:pick', event => {
  const rgb = event.detail.color
    .substring(4, event.detail.color.length - 1)
    .replace(/ /g, '')
    .split(',')
    .map(Number);

  // Handle color selection
  handleColorSelection(rgb);
  if(!LiveTryOn){
  updateImageColor();
  }
});
// document.getElementById('colorShadesList').addEventListener('click', function (event) {
//   if (event.target.classList.contains('color-shade')) {
//     const rgbValues = event.target.getAttribute('data-rgb').split(',').map(Number);
//     handleColorSelection(rgbValues);
//   }
// });


async function check_select() {
  let model_name = document.getElementById("select_model").value;

  if (model_name != name) {
    model_path = model_name + "/model.json"
    segmentationModel.dispose();
    segmentationModel = await loadModel();
    name = model_name;
    if (name == "tfjs_v6_100_256") {
      img_height = 256;
      img_width = 256;
    }
    else if (name == "tfjs_v6_100_384") {
      img_height = 384;
      img_width = 384;
    }
    else if (name == "tfjs_v6_100_512" || name == "tfjs_v7_100_512") {
      img_height = 512;
      img_width = 512;
    }
    else if (name == "tfjs_v6_100_256_512") {
      img_height = 512;
      img_width = 256;
    } else {
      img_height = 256;
      img_width = 256;
    }
  }

}

async function predict() {
  var i = 0;
  let outputBlended;
  let fps;
  let fpsTotal=0;
  while (continuePredicting) {
    
    if (i % 1 == 0 && LiveTryOn) {
      
      await check_select();

      const images = await getImage();
      const img = images[1];
      const img_show = images[0];

      var start1 = window.performance.now();

      if (i % 100) {
        const aspect_ratio = outputHeight/outputWidth;
        document.getElementById("mask").style.width = 0.5*window.innerHeight/aspect_ratio;
        document.getElementById("mask").style.height = 0.5*window.innerHeight;
        document.getElementById("tryon-live-canvas-0").style.width = 0.5 * window.innerHeight / aspect_ratio;
        document.getElementById("tryon-live-canvas-0").style.height = 0.5 * window.innerHeight;
        // console.log(outputHeight);
        // console.log(outputWidth);

      }

      
      var startTime = window.performance.now();

      // const img_preprocessed = tf.image.cropAndResize(img,[[0, 0.2, 1, 0.8]], [0], [img_size,img_size]) //.toFloat().div(255), [[0, 0.25, 1, 0.75]], [0], img_size).clipByValue(0,1) //.resizeBilinear([img_size,img_size]);

      
      
      
        const maskCanvas = Output_Video;
      const originalCanvas = Input_Canvas;
      if (i%2==0)
      {

     var start_model = window.performance.now();
      const output = segmentationModel.predict(img);
      
      




      // console.log('model')
      // console.log(end_model-start_model)

      // var endTime = window.performance.now()
      // console.log(`Model took  ${endTime - startTime}`)
      // var startTime = window.performance.now()
      
      
       tf.engine().startScope()


      

      var start_resize = window.performance.now();
      
      let outputMatt =  tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height,img_width]).clipByValue(0,1).reshape([img_height,img_width,1]).resizeBilinear([outputHeight,outputWidth]).squeeze());
      // const outputMatt = tf.tidy(() => outputMatt2.reshape([img_height,img_width,1]).resizeBilinear([outputHeight,outputWidth]).squeeze());

      Binarymask=outputMatt.mul(255)
      var end_model = window.performance.now();


      fps = (1000/(end_model-start_model)).toFixed(2) ;

      document.getElementById("model_fps").innerHTML = fps;

      
      var end_resize = window.performance.now();
      
     // if (i>0){
     	//outputBlended.dispose();
     	//let outputBlended;
     	//}
     	
     	if (i<2){
     	
	   avg=averageLuminanceFiltered(img_show.squeeze().dataSync(),outputMatt.dataSync())
	  
	  
	  //60 to 100 with 60 to dark and 100 to bright

	  // adjustWithLuminance = parseFloat(document.getElementById("strength").value)/100.0;
	  // additionLuminance=1
	  
	  adjustWithLuminance=mapLuminance(avg, 0.9, 1);
	  
	  additionLuminance= mapValue(adjustWithLuminance, 0.9, 1, 1, 0.8)
  }

	////////////////***************** Intensity Control Code//////////////////////
  
 
  if (avg<0.2 && hairColor[0] >0.5 && hairColor[1] >0.5)
  {
  const mask = tf.greater(outputMatt, 0.8);
  blendingIntensity=1.2
  outputMatt = tf.where(mask, outputMatt.add(blendingIntensity), outputMatt);
  }
 
  
  ////////////////////************Intensity Control Code Ends/////////////////////////
  
  
      outputBlended= blendMediapipe(img_show.squeeze(),  outputMatt,adjustWithLuminance,additionLuminance); //.resizeBilinear([256,256])
 
      outputMatt.dispose();
      // outputMatt2.dispose();
      output.dispose();
      // output.dispose();
      img.dispose();
      
      }
      
      var startTimeR = window.performance.now()
      const tensorToUse = hairColor[0] == null ? img_show.squeeze() : outputBlended;
      await tf.browser.toPixels(tensorToUse, maskCanvas);
      await tf.browser.toPixels(img_show.squeeze(), originalCanvas);  
      // await tf.browser.toPixels(outputBlended, maskCanvas);
      img_show.dispose();
      
      if (i>0 && (i+1)%2==0){
      
      tf.engine().endScope()
      }
      
       
      
      var endTimeR = window.performance.now()
      fps = (1000/(endTimeR-startTimeR)).toFixed(2);



    
      document.getElementById("render_fps").innerHTML = fps;

   


      
 

      var end1 = window.performance.now();

      fpsTotal = ((1000/(end1-start1))+fpsTotal)/2;

      document.getElementById("fps").innerHTML = fpsTotal.toFixed(2);

// do your thing
       
       
    
      
      
    //   console.log(
    //     "Make sure we cleaned up",
    //     tf.memory().numTensors,
    //     tf.memory().numBytesInGPU,
    //   );
      
      // var endTime = window.performance.now()
      // console.log(`Total took ${endTime - startTime}`)

    }
  
    i++;
    await tf.nextFrame();
  }
}


/**
 * Captures a frame from the webcam and normalizes it between -1 and 1.
 * Returns a batched image (1-element batch) of shape [1, w, h, c].
 */
async function getImage() {
  const img = await webcam.capture();

  let checkMobile = await isMobileBrowser();

  if (checkMobile) {  
    // Get the video's natural aspect ratio   
    let aspectRatio = Input_Video.videoWidth / Input_Video.videoHeight;   
    // Calculate the optimal output dimensions  
    outputWidth = Input_Video.width;  
    outputHeight = Input_Video.width / aspectRatio;  
    if (outputHeight > Input_Video.height) {  
      outputHeight =Input_Video.height;  
      outputWidth = Input_Video.height * aspectRatio;  
    }  
    // **CORRECTED:**  Set the video element's size  
    Input_Video.width = Math.floor(outputWidth);  
    Input_Video.height = Math.floor(outputHeight);  

    // Optional: Set the container's dimensions for more precise fitting  
    // (adjust container element selector)  
    // document.querySelector("#mask").style.width = `${outputWidth}px`;  
    // document.querySelector("#mask").style.height = `${outputHeight}px`;   
  }
  else{
      
    outputWidth = Input_Video.width;
    outputHeight = Input_Video.height;
    
    
  let aspectRatio = Input_Video.width / Input_Video.height;
  
  if (outputHeight > 720 || outputWidth > 720) {
      if (outputHeight > outputWidth) {
          outputHeight = 720;
          outputWidth = Math.round(outputHeight * aspectRatio);
      } else {
          outputWidth = 720;
          outputHeight = Math.round(outputWidth / aspectRatio);
      }
  }
  
  }

  // console.log(img);


  // const processedImg = tf.tidy(() => {

  //   const x = tf.expand_dims(img,0);
  //   const y = tf.to_float(x);
  //   const z = tf.div(y,255);


  //   return x;


  // });
  // console.log(img)

  const processedImg = tf.tidy(() => img.expandDims(0).toFloat().div(255));

  // keep the original image to show

  const processedImg2 = tf.image.cropAndResize(processedImg, [[0.0, 0.0, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');

  const processedImg3 = processedImg2.resizeBilinear([img_height, img_width]);

  img.dispose();
  processedImg.dispose();
  return [processedImg2, processedImg3];
}

tf.enableProdMode();

async function init() {
  await tf.ready();
  const devices = await navigator.mediaDevices.enumerateDevices();
  let success = false;
  await navigator.mediaDevices.enumerateDevices().then(async function (devices) {
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === 'videoinput') {
        try {
          const webcamConfig = { deviceId: device.deviceId };
          webcam = await tfd.webcam(document.getElementById('webcam'), webcamConfig);
          success = true;
        }
        catch (e) {
        }
      }
    }
  });
  if (success == false) {
    document.getElementById("no-webcam").innerHTML = 'No webcam could be opened';
  } else {
    document.getElementById("no-webcam").innerHTML = '';
  }
  segmentationModel = await loadModel();
  console.log(tf.getBackend());
  // Warm up the model. This uploads weights to the GPU and compiles the WebGL
  // programs so the first time we collect data from the webcam it will be
  // quick.
  //const screenShot = await webcam.capture();
  //screenShot.dispose();
  predict();
}


async function initapp() {
  await tf.ready();
  const devices = await navigator.mediaDevices.enumerateDevices();

  segmentationModel = await loadModel();
 

  // Warm up the model. This uploads weights to the GPU and compiles the WebGL
  // programs so the first time we collect data from the webcam it will be
  // quick.
  //const screenShot = await webcam.capture();

}


// Initialize the application.

//tf.setBackend('cpu');
tf.ready().then(() => {initapp()});
//  tf.setBackend('webgl').then(() => init());


function get_tensor_processed(inputMat) {

  outputWidth = Input_Image.width;
  outputHeight = Input_Image.height;

let aspectRatio = Input_Image.width / Input_Image.height;

if (outputHeight > 720 || outputWidth > 720) {
    if (outputHeight > outputWidth) {
        outputHeight = 720;
        outputWidth = Math.round(outputHeight * aspectRatio);
    } else {
        outputWidth = 720;
        outputHeight = Math.round(outputWidth / aspectRatio);
    }
}


  
  tensor = tf.tensor(inputMat.data, [inputMat.rows, inputMat.cols, 3])
  height = '';
  width = '';
  var image = Input_Image;
  
  // console.log("Inside preprocess 1");
  tensor = tf.image.cropAndResize(tensor.expandDims(0), [[0.0, 0.0, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');
  const processedImg2 = tf.tidy(() => tensor.toFloat().div(255));
  // keep the original image to show
  //const processedImg2 = tf.image.cropAndResize(processedImg, [[0.0, 0.0, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');
  const processedImg3 = processedImg2.resizeBilinear([img_height, img_width]);
  return [processedImg2, processedImg3];
}
// Canvases ZOOM IN ZOOM OUT

function canvasZoomIn(display_canvas_id,input_canvas_id) {
  let canvasWidth = document.querySelector(display_canvas_id).offsetWidth;
  let canvasHeight = document.querySelector(display_canvas_id).offsetHeight;
  document.querySelector(".canvas-container").style.width =
    canvasWidth + "px";
  document.querySelector(".canvas-container").style.height =
    canvasHeight + "px";
  console.log(`width - ${canvasWidth}, height - ${canvasHeight}`);
  var displayCanvas = document.querySelector(display_canvas_id);
  var inputCanvas = document.querySelector(input_canvas_id);
  var scale = (SCALE_ZOOM /=
    SCALE_MULTIPLIER);
  if(LiveTryOn && scale <= MAX_SCALE_ZOOM ){
    displayCanvas.style.transformOrigin = 'top center';
    displayCanvas.style.transform = `scale(${-scale}, ${scale})`;
    inputCanvas.style.transformOrigin = 'top center';
    inputCanvas.style.transform = `scale(${-scale}, ${scale})`;
  } 
  else if (scale <= MAX_SCALE_ZOOM) {
    displayCanvas.style.transformOrigin = 'top center';
    displayCanvas.style.transform = `scale(${scale}, ${scale})`;
    inputCanvas.style.transformOrigin = 'top center';
    inputCanvas.style.transform = `scale(${scale}, ${scale})`;
  } else {
    var scale = (SCALE_ZOOM *=
      SCALE_MULTIPLIER);
  }
}

function canvasZoomOut(display_canvas_id,input_canvas_id) {
  var displayCanvas = document.querySelector(display_canvas_id);
  var inputCanvas = document.querySelector(input_canvas_id);
  var scale = (SCALE_ZOOM *=
   SCALE_MULTIPLIER);
   if(LiveTryOn && scale >= MIN_SCALE_ZOOM){
    displayCanvas.style.transform = `scale(${-scale}, ${scale})`;
    inputCanvas.style.transform = `scale(${-scale}, ${scale})`;
   } 
  else if(scale >= MIN_SCALE_ZOOM) {
    displayCanvas.style.transform = `scale(${scale}, ${scale})`;
    inputCanvas.style.transform = `scale(${scale}, ${scale})`;
  } else {
    var scale = (SCALE_ZOOM /=
      SCALE_MULTIPLIER);
  }
}
 async function resetZoom() {
  SCALE_ZOOM = DEFAULT_SCALE_ZOOM;
  SCALE_MULTIPLIER = DEFAULT_SCALE_MULTIPLIER;
  Input_Canvas.style.transform = '';
  Output_Canvas.style.transform = '';
  Output_Video.style.transform = '';
}

let imageContainer = document.getElementById('imageContainer');
let imgElement = document.getElementById('img2');
let inputElement = document.getElementById('fileInput');
// const preLoadedImages = imageContainer.querySelectorAll('.modal-img');
// preLoadedImages.forEach(image => {
//   image.addEventListener('click', () => {
//     imgElement.src = image.src;
//     document.getElementById('myModal').style.display='none';
//   });
// });
// Upload Image Function
inputElement.addEventListener('change', async (e) => {
  const file = e.target.files[0];
  if (file) {
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    const isHEIC = fileExtension === 'heic' || fileExtension === 'heif';
    if (isHEIC) {
      // Convert HEIC Image to JPG
      const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
      const jpgFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
      compressAndDisplay(jpgFile);
    } else {
      compressAndDisplay(file);
    }
  }
});
// Compressing the Image and Displaying it.
async function compressAndDisplay(file) {
if (!file) {
  console.error('File is undefined or null.');
  return;
}
const fileSize = file.size;
// Checking the Image Size and Adjusting the Quality accordingly.
let quality = fileSize <= 1024 * 1024 ? 0.8 : 0.2;
try {
  const compressedFile = await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
  imgElement.src = URL.createObjectURL(compressedFile);
} catch (error) {
  console.error('Compression failed:', error);
}
}

Input_Image.style.display = 'none';
document.getElementById('webcam').style.display = 'none';

imgElement.onload = async function () {
// console.log("Here is the code 1")
  Tryon_Media_Container.style.display='none';

  Try_On_Area.style.display = 'block';
  Flipper_Icon.style.display = 'none';
  //segmentationModel = await loadModel();
  Output_Canvas.style.display = 'block';
  Back_Button.style.display = 'block';
  Scroll_Down_Target.scrollIntoView({ behavior: 'smooth',block: 'start'});
  
  /////////////////////////////////***********************
  
  
      // Extract the white areas from the original image
 //const grayscaleImage = image.mean(2);
   // Threshold the grayscale image to identify hair regions
   /*
    const threshold = 0.9; // Adjust threshold as needed
    const hairAreas = tf.cast(tf.greater(weight, threshold), 'float32');
    
     

    // Mask the grayscale image with the hair areas
    const hairGrayscale = grayscaleImage.mul(hairAreas);
     const edges = applySobelFilters(hairGrayscale);
     
     
     
    const edgeThreshold = 0.9; // Adjust as needed
  const edgeMask = edges.less(edgeThreshold).toFloat(); //
  const edgeMask2= edges.mul(edgeMask)
  


    // Threshold the hair grayscale image to identify white areas
    const percentile = 90; // Adjust percentile as needed
    const hairMainValue = calculatePercentile(hairGrayscale.dataSync(), percentile);

    // Calculate the threshold based on the hair's main value
    const whiteThreshold = hairMainValue * 0.90; 
    
    const mask=tf.cast(tf.greater(hairGrayscale, whiteThreshold), 'float32')
    
    const whiteAreas = tf.onesLike(hairGrayscale).sub(hairGrayscale.mul(edgeMask2));
    
    const resultTensor = hairGrayscale.mul(edgeMask2).div(2)
    //const resultTensor = mask.mul(whiteAreas)
    // Blend the recoloring color with the white areas of the original image
    */
    
    
  
  //////////////////////////////////*****************************
  
  
  
  
  let mat = cv.imread(imgElement);
  const inputMat = new cv.Mat();
  cv.cvtColor(mat, inputMat, cv.COLOR_RGBA2RGB);
  processed = get_tensor_processed(inputMat)
  
  showImage = processed[0]
  processedInputMat = processed[1]
  const maskCanvasShow = Output_Canvas;
   const originalCanvas = Input_Canvas;

   
  
  /*
  
  console.log("Here is the code3")
  const output = segmentationModel.predict(processedInputMat);

 
  var start_resize = window.performance.now();
  console.log("Here is the code4")
  const outputMatt = tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height, img_width]).clipByValue(0, 1).reshape([img_height, img_width, 1]).resizeBilinear([outputHeight, outputWidth]).squeeze());
  console.log("Here is the code5")
  avg=averageLuminanceFiltered(showImage.squeeze().dataSync(),outputMatt.dataSync())
  adjustWithLuminance=mapLuminance(avg, 0.8, 0.9);
  additionLuminance= mapValue(adjustWithLuminance, 0.9, 1, 1, 0.8);
   console.log("Here is the code6")
  let outputBlended = blendMediapipe(showImage.squeeze(),  outputMatt,adjustWithLuminance,additionLuminance);
  console.log("Here is the code7")
  // const outputBlended = blendMediapipe(showImage.squeeze(), outputMatt); //.resizeBilinear([256,256])
  
  
  */
  const maskPromise = tf.browser.toPixels(showImage.squeeze(), maskCanvasShow);
  const originalPromise = LiveTryOn ? Promise.resolve() : tf.browser.toPixels(showImage.squeeze(), originalCanvas);
  await Promise.all([maskPromise, originalPromise]);
  canvasZoomIn('#outputCanvas','#tryon-live-canvas-0');
  canvasZoomOut('#outputCanvas','#tryon-live-canvas-0');
  if (window.matchMedia("(max-width: 798px)").matches) { 
    Tryon_Container.style.width = "100vw";
  }
  else{
    Tryon_Container.style.width = Output_Canvas.offsetWidth + "px"; 
  }
  
  mat.delete();
  inputMat.delete();
};
  
document.addEventListener('DOMContentLoaded', function () {
 // Replace with the actual ID of the element that shows the canvases 

 function resetContainerTryonMedia() { 

  if (window.matchMedia("(max-width: 798px)").matches) { 
    Tryon_Container.style.width = "100vw"; 
  } else { 
    Tryon_Container.style.width = "100%"; 
  } 
// Set other styles 
  Tryon_Container.style.height = "100%"; 
  Tryon_Container.style.float = "left"; 
} 
 
  // Function to Start live camera
  document.getElementById('showWebcamBtn').addEventListener('click',async function startLiveCamera(event) {
    event.preventDefault();
    Back_Button.style.display='block';
    Tryon_Media_Container.style.display='none';
    currentCamera = 'user';
    Try_On_Area.style.display = 'block';
    Output_Video.style.display = 'block';
    Output_Video.style.transform = 'scaleX(-1)';
    Input_Canvas.style.transform = 'scaleX(-1)';
    outputWidth = null;
    outputHeight = null;
    CanvasesContainer.style.width = '';
    CanvasesContainer.style.height = ''; 
    Flipper_Icon.style.display = 'block';
    LiveTryOn = true;
    continuePredicting = true;
    tf.setBackend('webgl').then(() => init()); 
  });
  document.getElementById('upload-img-section').addEventListener('click', function uploadImage() {
    inputElement.click();
  });
  // Tryon Button one

  // document.getElementById('showTryonbtn').addEventListener('click', handleTryOnClick);
  // document.getElementById('showTryonbtntwo').addEventListener('click', handleTryOnClick);
  // Back Button
  document.getElementById('backButton').addEventListener('click', async function (event) {
    event.preventDefault();
    await resetZoom();
    await backOperations();
    resetContainerTryonMedia();
    outputWidth = null;
    outputHeight = null;
    CanvasesContainer.style.width = '';
    CanvasesContainer.style.height = ''; 
    Input_Canvas.style.width='';
    await clearCanvases();
    Input_Canvas.style.transform = 'scaleX(1)';
    Try_On_Area.style.display = 'none';
    Tryon_Media_Container.style.display='block';
    if(LiveTryOn){
    await turnOffCamera();
    await stopPredict();
    }
    LiveTryOn = false;
});

    // Zoom in Zoom Out Functionalities Below
    document.getElementById('zoom-in').addEventListener('click', (event) => handleZoom(event, true));
    document.getElementById('zoom-out').addEventListener('click', (event) => handleZoom(event, false));

//Camera Flipper 
document.getElementById('flipper').addEventListener('click', async function (event) {
   event.preventDefault();
  await flipCamera();
});


// Intensity update for upload image
if (window.location.pathname.includes('/builder.html')) { 
document.getElementById('strength').addEventListener('input', function (event) {
  if(!LiveTryOn){ 
  updateImageColor();
  }
});
}

  // Select Shades
  let DOMShadeColors = document.getElementsByClassName('shade-color');
  const clrField = document.querySelector('.clr-field');
  
  for (i = 0; i < DOMShadeColors.length; i++) {
    DOMShadeColors[i].addEventListener("click", function () {

      var rgbValues = this.getAttribute('data-rgb').split(',').map(Number);
     
      if (window.location.pathname.includes('/builder.html')) { 
      clrField.style.color = `rgb(${rgbValues.join(',')})`; 
      }
      const normalizedRGBDOM = rgbValues.map(value => value / 255.0);

      hairColor = normalizedRGBDOM;

      if(!LiveTryOn){
      updateImageColor();
      }
    });
  }
});
function handleTryOnClick(event) {
  event.preventDefault();
  TopContentSection.classList.remove('margin-class');
  Try_On_Controls_Section.style.display = 'block';
  Try_On_Controls_Section.scrollIntoView({ behavior: 'smooth' });
}
function handleZoom(event, zoomIn) {
  event.preventDefault();
  const canvasId = LiveTryOn ? '#mask' : '#outputCanvas';
  const zoomFunction = zoomIn ? canvasZoomIn : canvasZoomOut;
  zoomFunction(canvasId, '#tryon-live-canvas-0');
}
async function backOperations(){
  hairColor = [null,null,null];
  // TryOnBtn.style.display='block';
  let Splitter_Icon = document.getElementById('spliter');
  if (Splitter_Icon.classList.contains('active')) {
    Splitter_Icon.classList.remove('active');
    document.getElementById('baseCanvasSplitter').style.display='none';
    document.querySelector('.splitter-range-container').style.display = 'none';
    document.querySelector('.splitter-handler').style.display = 'none'; 
    document.querySelector('#baseCanvasSplitter').style.width = '0';
    document.querySelector('#splitterController').style.backgroundSize = "50% 100%";
    document.querySelector('.splitter-handler').style.left = "48%";
    document.querySelector('#splitterController').value = 50;
  }
  console.log('Execution Successfull');
}
async function clearCanvases(){
  let canvasInput = Input_Canvas;
  let canvasVideo = Output_Video;
  let canvasImage = document.getElementById('outputCanvas');
  let ctx_Input = canvasInput.getContext("2d");
  let ctx_Video = canvasVideo.getContext("2d");
  let ctx_Image = canvasImage.getContext("2d");
  ctx_Input.clearRect(0, 0, canvasInput.width, canvasInput.height);
  if(LiveTryOn){
  ctx_Video.clearRect(0, 0, canvasVideo.width, canvasVideo.height);
  Output_Video.style.display = 'none';
  }
  ctx_Image.clearRect(0, 0, canvasImage.width, canvasImage.height);
  Input_Canvas.style.display = 'none';
  Output_Canvas.style.display = 'none';
}
async function updateImageColor(){
  tf.engine().startScope();
  let mat = cv.imread(imgElement);
  const inputMat = new cv.Mat();
  const output = segmentationModel.predict(processedInputMat);
  const maskCanvasshow = document.getElementById('outputCanvas');
  var start_resize = window.performance.now();
  let outputMatt = tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height, img_width]).clipByValue(0, 1).reshape([img_height, img_width, 1]).resizeBilinear([outputHeight, outputWidth]).squeeze());
  avg=averageLuminanceFiltered(showImage.squeeze().dataSync(),outputMatt.dataSync())
  adjustWithLuminance=mapLuminance(avg, 0.9, 1);
  additionLuminance= mapValue(adjustWithLuminance, 0.9, 1, 1, 0.8);
 const mask = tf.greater(outputMatt, 0.8);
 //const mask2 = (weight > 0.3) & (weight < 0.6)
 //const mask3 = (weight > 0.6) & (weight < 0.8);
 let blendingIntensity=0.8
  if (avg<0.2 && hairColor[0] >0.5 && hairColor[1] >0.5)
  {
  blendingIntensity=1.2
  }
  outputMatt = tf.where(mask, outputMatt.add(blendingIntensity), outputMatt);
  
  
  
  
  let outputBlended = blendMediapipe(showImage.squeeze(),  outputMatt,adjustWithLuminance,additionLuminance,avg); //.resizeBilinear([256,256])
  await tf.browser.toPixels(outputBlended, maskCanvasshow);
  mat.delete();
  inputMat.delete();
  tf.engine().endScope();
}
async function flipCamera() {
  currentCamera = currentCamera === 'user' ? 'environment' : 'user'; 
  // Stop the execution of the predict function
  await webcam.stop();
  stopPredict();
  
  // Start the camera with the new configuration
  const webcamConfig = { facingMode: currentCamera };
  webcam = await tfd.webcam(document.getElementById('webcam'), webcamConfig);
  
  // Wait for the webcam stream to be fully initialized and captured
  await new Promise(resolve => setTimeout(resolve, 500));
  if (!continuePredicting) {
    continuePredicting = true;
    predict();
  }
}
async function stopPredict() {
  continuePredicting = false;
  tf.engine().endScope();
  console.log('Scope Ended');
}
async function isMobileDevice() {
  const userAgent = navigator.userAgent;
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
  return mobileRegex.test(userAgent);
}
async function isMobileBrowser() {
  // Check if the device is mobile
  const isMobile = await isMobileDevice();

  // Check if the browser is a mobile browser (including Safari on iOS)
  const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Mobile/i.test(navigator.userAgent);

  return isMobile || isMobileBrowser;
}

async function turnOffCamera() {
  if (webcam) {
   await webcam.stop();
    console.log('Camera turned off.');
  } else {
    console.error('Webcam is not initialized.');
  }
}

// arrShadesData.tabs.forEach(tab => {
//   const tabButton = document.createElement('button');
//   tabButton.textContent = tab.label;
//   tabButton.classList.add('tablink');
//   tabButton.setAttribute('data-tab', tab.id);
//   tabButton.onclick = () => openTabContent(tab.id); 
//   tabsContainer.appendChild(tabButton);
//   if (tab.id === "brown") {
//       tabButton.classList.add('active');
//   }
// });

// arrShadesData.color_palettes.forEach(palette => {
//   const paletteElement = document.createElement('ul');
//   paletteElement.classList.add('color-palette', 'tabcontent');
//   paletteElement.id = palette.id;
//   palette.shades.forEach(shade => {
//       const shadeElement = document.createElement('li');
//       shadeElement.classList.add('color-shade');
//       shadeElement.innerHTML = `
//           <div class="shade-color" data-rgb="${JSON.stringify(shade.rgb)}">
//               <img src="${shade.image_src}" alt="Image" />
//           </div>
//           <span class="shade-name" style="display: none;">${shade.name}</span>
//       `;
//       shadeElement.querySelector('.shade-color').addEventListener('click', function() {
//           toggleShadeNameVisibility(shade.name);
//       });
//       paletteElement.appendChild(shadeElement);
//   });
//   colorPaletteContainer.appendChild(paletteElement);
// });

// function toggleShadeNameVisibility(shadeName) {
//     selectedShadeNameDiv.textContent = shadeName;
//     selectedShadeNameDiv.style.display = 'block';
// }

// function openTabContent(tabId) {
//   // Iterate over tab contents
//   const tabContents = document.querySelectorAll('.tabcontent');
//   tabContents.forEach(tabContent => {
//       // Hide all tab contents except the one with the matching tab ID
//       if (tabContent.id === tabId) {
//           tabContent.style.display = 'block';
//       } else {
//           tabContent.style.display = 'none';
//       }
//   });

//   // Update active class for the tab button
//   const selectedTabButton = document.querySelector(`button[data-tab="${tabId}"]`);
//   if (selectedTabButton) {
//       // Remove active class from all tab buttons
//       const tabButtons = document.querySelectorAll('.tablink');
//       tabButtons.forEach(tabButton => {
//           tabButton.classList.remove('active');
//       });
//       selectedTabButton.classList.add('active');
//   }
// }

// // Initial setup: Open default tab content and set active class
// openTabContent('brown');
